import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Stack } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { keyframes } from '@mui/system';

const IMAGES = [
  'bild1.jpg',
  'bild2.jpg',
  'bild3.jpg',
]

const bounceAnim = keyframes`
0%, 25%, 50%, 75%, 100% {
  transform: translateY(0);
}
40% {
  transform: translateY(-30px),
}
60% {
  transform: translateY(-12px);
}
`


function BgImage(props) {
  const { children } = props

  return (
    <Box sx={{
      height: '100vh',
      width: '100%',
      paddingTop: '60vh',
      backgroundImage: `url("${process.env.PUBLIC_URL}/img/bild1.jpg")`,
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
    }}>
      { children }
    </Box>
  )
}


function App() {
  return (
    <Box sx={{
      width: '100%',
      height: '200vh',
      backgroundColor: 'transparent',
    }}>
      <img
        style={{
          height: '100vh',
          margin: 'auto',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        }} 
        src={`${process.env.PUBLIC_URL}/img/bild1.jpg`} />
      <Box sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}>
        <Box sx={{
          paddingTop: '10vh',
          paddingBottom: '5vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));',
        }}>
        <Typography sx={{
            textAlign: 'center',
            fontWeight: '400',
            color: 'coral',
            paddingLeft: '10px',
            paddingRight: '10px',
        }} variant='h1'>Bröllop 3/6</Typography>
        <KeyboardArrowDownIcon sx={{
          color: 'coral',
          width: '3em',
          height: '3em',
          animation: `${bounceAnim} 2s linear infinite`
          }}></KeyboardArrowDownIcon>
        </Box>
      </Box>
      <Stack spacing={5} sx={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          height: '100%',
          width: '100%',
        }}>
          {/* <Typography sx={{
            textAlign: 'center',
            color: 'coral',
            fontWeight: '400',
            paddingLeft: '10px',
            paddingRight: '10px',
          }} variant='h3'>Program:</Typography> */}
          <Typography sx={{
            textAlign: 'center',
            color: 'coral',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}  variant='h5'>
            Vigseln startar 16:30 i Ulriksdals slottskapell. Efter bröllopet tar vi en kort promenad (ca 5min) upp till Ulriksdals värdshus för middag och fest.
          </Typography>
          <Typography sx={{
            textAlign: 'center',
            color: 'coral',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}  variant='h5'>
            Allt vi önskar är egentligen att fira vår dag tillsammans med er! Om ni ändå känner att ni vill ha med något skulle vi uppskatta ett bidrag till vår bröllopsresa. För er som önskar swisha går det att göra till en av våra toastmasters, Anna Nordlöv, på 073 - 535 58 77.
          </Typography>
          <Typography sx={{
            textAlign: 'center',
            color: 'coral',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}  variant='h5'>Vill du hålla tal under middagen, anmäl till våra toastmasters Ivar och Anna på toastmaster@svartholm.nu. Anmäl tal innan 28/5.</Typography>
          <Typography sx={{
            textAlign: 'center',
            color: 'coral',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}  variant='h5'>Ulriksdals slottskapell ligger precis söder om Ulriksdals slott. Smidigaste resvägen är tunnelbana till Bergshamra (röda linjen mot Mörby centrum). Därefter ca 15 minuters promenad genom slottsparken.</Typography>
          <Typography sx={{
            textAlign: 'center',
            color: 'coral',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}  variant='h5'>Tilde och Ruben</Typography>
        </Stack>
    </Box>
  );
}

export default App;
